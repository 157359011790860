* {
  box-sizing: border-box;
}

.wrapper {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0 30px;
  background: #ffffff;
  overflow: auto;
  z-index: 9999;
}

.wrapper {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.wrapper::-webkit-scrollbar {
  display: none;
}

.topNav {
  position: fixed;
  top: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  min-height: 67px;
  background: #ffffff;
  padding: 0 24px;
  z-index: 1;
}

.backIcon {
  width: 25px;
  min-height: 25px;
  cursor: pointer;
}

.emptySpace {
  width: 100%;
  min-height: 97px;
}

.loginLogo {
  min-height: 96px;
}

.loginTitle {
  width: 100%;
  color: var(--logo);
  font-size: 20px;
  font-weight: 600;
  margin: 56px 0 3px 0;
}

.inputId {
  font-weight: 500;
  font-size: 16px;
  padding: 0px 22px;
  width: 100%;
  min-height: 55px;
  background: var(--input-box-grey);
  border: transparent;
  border-radius: 10px;
  margin-top: 14px;
}

.inputPW {
  font-weight: 500;
  font-size: 16px;
  padding: 0px 22px;
  width: 100%;
  min-height: 55px;
  background: var(--input-box-grey);
  border: transparent;
  border-radius: 10px;
  margin-top: 14px;
}

.inputId:focus,
.inputPW:focus {
  outline: none;
  border: 1px solid var(--input-box-grey);
  background: #ffffff;
}

.loginButton {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 55px;
  background: var(--primary);
  border-radius: 10px;
  color: #ffffff;
  font-size: 20px;
  font-weight: 600;
  margin-top: 37px;
}

.findAccount {
  cursor: pointer;
  display: flex;
  flex: 1 1;
  flex-direction: row;
  align-items: center;
  color: var(--text-grey3);
  font-size: 14px;
  font-weight: 400;
  margin-top: 37px;
  margin-bottom: 166px;
}

.findAccount > p {
  margin: 0 2px;
}

.emptyBottomSpace {
  width: 100%;
  min-height: 58px;
}

.bottomLicense {
  flex: 1 1;
  width: 100%;
  text-align: center;
  color: var(--text-grey3);
  font-size: 10px;
  font-weight: 700;
}
