.pagination {
    display: flex;
    justify-content: center;
}

ul {
    list-style: none;
    padding: 0;
}

ul.pagination li:first-child {
    border-left: 1px solid #E5E5E5;
    border-radius: 3px 0px 0px 3px;
}

ul.pagination li:last-child {
    border-radius: 0px 3px 3px 0px;
}

ul.pagination li {
    display: inline-block;
    width: 34px;
    height: 34px;
    border-top: 1px solid #E5E5E5;
    border-bottom: 1px solid #E5E5E5;
    border-right: 1px solid #E5E5E5;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    cursor: pointer;
}


ul.pagination li a {
    display: flex;
    text-decoration: none;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: var(--primary);
    align-items: center;
}

ul.pagination li.active a {
    color: white;
    text-decoration: none !important;
}

ul.pagination li.active {
    background-color: var(--primary);
    border: 1px solid var(--primary);
}

.page-selection {
    width: 48px;
    height: 30px;
    color: #337ab7;
}