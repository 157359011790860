.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  animation-name: fadeIn;
  animation-duration: 0.2s;
  animation-delay: 0.01s;
  animation-fill-mode: forwards;
  z-index: 9999999;
}

.innerWrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  width: 80%;
  padding: 30px;
  border-radius: 13px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
}

.dialogCloseButton {
  position: absolute;
  cursor: pointer;
  top: 20px;
  right: 20px;
}

.title {
  width: 100%;
  color: var(--black);
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 21px;
}

.loginButton {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50px;
  border-radius: 10px;
  background: var(--primary);
  color: #ffffff;
  font-size: 18px;
  font-weight: 600;
  margin-top: 20px;
}

.closeButton {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50px;
  border-radius: 10px;
  border: 1px solid var(--primary);
  color: var(--primary);
  font-size: 18px;
  font-weight: 600;
  margin-top: 20px;
}

.fade-in {
  opacity: 1;
  animation: fadeIn ease-in-out 0.2s;
}

.fade-out {
  opacity: 0;
  animation: fadeOut ease-in-out 0.2s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.imageBox {
  width: 80px;
  height: 80px;
  object-fit: cover;
}

.inputTitle {
  width: 100%;
  color: var(--black);
  font-size: 14px;
  font-weight: 400;
  margin-top: 20px;
}

.inputId {
  font-weight: 500;
  font-size: 16px;
  padding: 0px 22px;
  width: 100%;
  min-height: 55px;
  background: #ffffff;
  border: 1px solid var(--light-grey);
  border-radius: 10px;
  margin-top: 5px;
}

.inputId:focus {
  outline: none;
  border: 1px solid #000000;
  background: #ffffff;
}

.imageContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 18px;
  gap: 20px;
  margin-bottom: 11px;
}

.imageContainer label {
  width: fit-content;
}

.afterImageContainer {
  border-radius: 13px;
  width: 79px;
  height: 79px;
  object-fit: cover;
}

.profileBox {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 79px;
  height: 79px;
  border-radius: 13px;
  background: var(--background-grey);
  object-fit: cover;
}

.genderRadioGroup {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin-top: 17px;
  margin-bottom: 10px;
}

.radioBox {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 41px;
}

.selectedRadio {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 19px;
  height: 19px;
  border-radius: 50%;
  border: 1px solid var(--primary);
}

.selectedRadioInner {
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background: var(--primary);
}

.unselectedRadio {
  width: 19px;
  height: 19px;
  border-radius: 50%;
  border: 1px solid var(--light-grey);
}

.radioText {
  color: var(--black);
  font-size: 14px;
  font-weight: 600;
  margin-left: 7px;
}

.imageBoxWrapper {
  width: 80px;
  height: 110px;
  margin-right: 20px;
}

.deleteButton {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 3px 0;
  border-radius: 5px;
  font-size: 14px;
  color: #ffffff;
  background: var(--red);
}

.rowBox {
  display: flex;
  align-items: center;
}