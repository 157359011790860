.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  animation-name: fadeIn;
  animation-duration: 0.2s;
  animation-delay: 0.01s;
  animation-fill-mode: forwards;
  z-index: 9999999;
}

.innerWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  width: 80%;
  padding: 30px;
  border-radius: 13px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
}

.title {
  width: 100%;
  color: var(--black);
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 21px;
}

.image {
  width: 100%;
  height: 80vh;
  object-fit: contain;
}

.loginButton {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50px;
  border-radius: 10px;
  background: var(--primary);
  color: #ffffff;
  font-size: 18px;
  font-weight: 600;
  margin-top: 20px;
}

.closeButton {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50px;
  border-radius: 10px;
  border: 1px solid var(--primary);
  color: var(--primary);
  font-size: 18px;
  font-weight: 600;
  margin-top: 20px;
}

.fade-in {
  opacity: 1;
  animation: fadeIn ease-in-out 0.2s;
}

.fade-out {
  opacity: 0;
  animation: fadeOut ease-in-out 0.2s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.inputTopTitle {
  width: 100%;
  color: var(--black);
  font-size: 14px;
  font-weight: 400;
}

.inputId {
  font-weight: 500;
  font-size: 16px;
  padding: 0px 22px;
  width: 100%;
  min-height: 55px;
  background: #ffffff;
  border: 1px solid var(--light-grey);
  border-radius: 10px;
  margin-top: 5px;
}

.inputId:focus {
  outline: none;
  border: 1px solid #000000;
  background: #ffffff;
}