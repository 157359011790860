.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  animation-name: fadeIn;
  animation-duration: 0.2s;
  animation-delay: 0.01s;
  animation-fill-mode: forwards;
  z-index: 9999999;
}

.innerWrapper {
  position: relative;
  background: #ffffff;
  border-radius: 13px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
}

.dialogContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  max-height: 80vh;
  padding: 50px;
  overflow: auto;
  gap: 10px;
}

.dialogContainer::-webkit-scrollbar {
  width: 10px;
}

.dialogContainer::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: var(--text-grey2);
  width: 10px;
}

.dialogContainer::-webkit-scrollbar-track {
  border-top-right-radius: 13px;
  border-bottom-right-radius: 13px;
  background-color: #ffffff;
}

.dialogContainer p {
  text-align: center;
  color: var(--black);
  font-size: 18px;
  font-weight: 600;
}

.dialogCloseButton {
  position: absolute;
  cursor: pointer;
  top: 20px;
  right: 20px;
}

.title {
  width: 100%;
  color: var(--black);
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 21px;
}

.image {
  width: 100%;
  max-height: 80vh;
  object-fit: contain;
}

.loginButton {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50px;
  border-radius: 10px;
  background: var(--primary);
  color: #ffffff;
  font-size: 18px;
  font-weight: 600;
  margin-top: 20px;
}

.closeButton {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50px;
  border-radius: 10px;
  border: 1px solid var(--primary);
  color: var(--primary);
  font-size: 18px;
  font-weight: 600;
  margin-top: 20px;
}

.fade-in {
  opacity: 1;
  animation: fadeIn ease-in-out 0.2s;
}

.fade-out {
  opacity: 0;
  animation: fadeOut ease-in-out 0.2s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
