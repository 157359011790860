.imageContainer {
    position: relative;
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 18px;
    margin-bottom: 11px;
}

.afterImageContainer {
    cursor: pointer;
    border-radius: 13px;
    width: 79px;
    height: 79px;
    object-fit: cover;
}

.imageBox {
    width: 80px;
    height: 80px;
    object-fit: cover;
}

.imageBoxWrapper {
    width: 80px;
    margin-right: 10px;
}

.deleteButton {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 3px 0;
    border-radius: 5px;
    font-size: 14px;
    color: #ffffff;
    background: var(--red);
}

.profileBox {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 79px;
    height: 79px;
    border-radius: 13px;
    background: var(--background-grey);
    object-fit: cover;
}