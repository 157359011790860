.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.03);
  opacity: 0;
  animation-name: fadeIn;
  animation-duration: 0.2s;
  animation-delay: 0.01s;
  animation-fill-mode: forwards;
  z-index: 9999999;
}

.navLoading {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100%;
  height: calc(100% - 58px);
  background: rgba(0, 0, 0, 0.03);
  opacity: 0;
  animation-name: fadeIn;
  animation-duration: 0.2s;
  animation-delay: 0.01s;
  animation-fill-mode: forwards;
  z-index: 9999999;
}

.image {
  width: 50px;
  height: 50px;
}

.fade-in {
  opacity: 1;
  animation: fadeIn ease-in-out 0.2s;
}

.fade-out {
  opacity: 0;
  animation: fadeOut ease-in-out 0.2s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
