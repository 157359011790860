.wrapper {
  display: flex;
  flex-direction: column;
  min-width: calc(100vw - 263px);
  min-height: 100vh;
  padding-left: 263px;
  background-color: var(--background-grey);
  overflow: auto;
}

.innerWrapper {
  padding: 50px;
}

.titleBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 40px;
}

.LeftArrow {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
  cursor: pointer;
  width: 30px;
  height: 30px;
}

.titleText {
  font-size: 25px;
  font-weight: 700;
}

.tableTitle {
  font-size: 18px;
  font-weight: 700;
  margin: 10px 0;
}

.tableContainer {
  display: flex;
  gap: 20px;
}

.table {
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 50px;
}

.table th,
.table td {
  border: 1px solid #ddd;
  padding: 8px 20px;
  text-align: left;
  height: 50px;
}

.table th {
  background-color: var(--pure-white);
  font-weight: bold;
}

.table td {
  background-color: #ffffff;
}

.firstColumn {
  background-color: var(--primary) !important;
  color: white;
  text-align: left;
  font-size: 16px;
  font-weight: bold;
}

.deleteButton {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  padding: 10px 15px;
  border-radius: 5px;
  font-size: 16px;
  color: #ffffff;
  background: var(--red);
}
