.wrapper {
  position: absolute;
  top: 0px;
  left: 263px;
  width: calc(100vw - 263px);
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  background-color: var(--background-grey);
}

.title {
  width: 100%;
  text-align: center;
  font-size: 36px;
  font-weight: 900;
  color: var(--main);
  margin-bottom: 100px;
}

.innerWrapper {
  display: flex;
  flex-direction: column;
  padding: 50px;
  border-radius: 15px;
  background-color: var(--pure-white);
  width: 432px;
  min-width: 432px;
  margin: auto;
}

.categoryTitle {
  font-size: 14px;
  font-weight: 700;
  color: var(--grey);
  margin-top: 20px;
}

.inputPW {
  font-weight: 500;
  font-size: 16px;
  padding-left: 11px;
  padding-right: 7px;
  width: 100%;
  min-height: 45px;
  border: 1px solid var(--light-grey);
  border-radius: 8px;
  margin-top: 10px;
}

.button {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  background-color: var(--primary);
  width: 100%;
  height: 45px;
  font-size: 16px;
  font-weight: 600;
  color: var(--pure-white);
  margin-top: 50px;
}
