.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999999;
}

.innerWrapper {
  display: flex;
  flex-direction: column;
  background: #ffffff;
  width: 80%;
  max-height: 80%;
  padding: 30px;
  border-radius: 13px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  overflow: auto;
}

.map {
  width: 100%;
  height: 500px;
  border-radius: 13px;
  z-index: 999;
}

.loginButton {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50px;
  border-radius: 10px;
  background: var(--primary);
  color: #ffffff;
  font-size: 18px;
  font-weight: 600;
  margin-top: 50px;
}

.closeButton {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50px;
  border-radius: 10px;
  border: 1px solid var(--primary);
  color: var(--primary);
  font-size: 18px;
  font-weight: 600;
  margin-top: 20px;
}
