.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  animation-name: fadeIn;
  animation-duration: 0.2s;
  animation-delay: 0.01s;
  animation-fill-mode: forwards;
  z-index: 9999999;
}

.innerWrapper {
  display: flex;
  position: relative;
  flex-direction: column;
  background: #ffffff;
  width: 80%;
  max-height: 80%;
  border-radius: 13px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  overflow: auto;
}

.dialogContainer {
  display: flex;
  flex-direction: column;
  align-items: start;
  padding: 30px;
  min-width: 100%;
  width: 100%;
  height: 100%;
  overflow: auto;
}

.dialogContainer::-webkit-scrollbar {
  width: 10px;
}

.dialogContainer::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: var(--text-grey2);
  width: 10px;
}

.dialogContainer::-webkit-scrollbar-track {
  border-top-right-radius: 13px;
  border-bottom-right-radius: 13px;
  background-color: #ffffff;
}

.dialogCloseButton {
  position: absolute;
  cursor: pointer;
  top: 10px;
  right: 20px;
}

.title {
  width: 100%;
  color: var(--black);
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 21px;
}

.loginButton {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 50px;
  border-radius: 10px;
  background: var(--primary);
  color: #ffffff;
  font-size: 18px;
  font-weight: 600;
  margin-top: 50px;
}

.loginButton2 {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 50px;
  border-radius: 10px;
  background: var(--primary);
  color: #ffffff;
  font-size: 18px;
  font-weight: 600;
  margin-top: 10px;
}

.closeButton {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 50px;
  border-radius: 10px;
  border: 1px solid var(--primary);
  color: var(--primary);
  font-size: 18px;
  font-weight: 600;
  margin-top: 20px;
}

.closeButton2 {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 50px;
  border-radius: 10px;
  border: 1px solid var(--primary);
  color: var(--primary);
  font-size: 18px;
  font-weight: 600;
  margin-top: 10px;
}

.fade-in {
  opacity: 1;
  animation: fadeIn ease-in-out 0.2s;
}

.fade-out {
  opacity: 0;
  animation: fadeOut ease-in-out 0.2s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.imageBox {
  width: 80px;
  height: 80px;
  object-fit: cover;
}

.imageBoxWrapper {
  width: 80px;
  height: 110px;
  margin-right: 10px;
}

.inputTitle {
  width: 100%;
  color: var(--black);
  font-size: 14px;
  font-weight: 400;
  margin-top: 20px;
}

.inputTopTitle {
  width: 100%;
  color: var(--black);
  font-size: 14px;
  font-weight: 400;
}

.inputId {
  font-weight: 500;
  font-size: 16px;
  padding: 0px 22px;
  width: 100%;
  min-height: 55px;
  background: #ffffff;
  border: 1px solid var(--light-grey);
  border-radius: 10px;
  margin-top: 5px;
}

.inputId:focus {
  outline: none;
  border: 1px solid #000000;
  background: #ffffff;
}

.defaultInput {
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
  padding: 0px 22px;
  width: 100%;
  min-height: 55px;
  background: #ffffff;
  border: 1px solid var(--light-grey);
  background: var(--background-grey);
  border-radius: 10px;
  margin-top: 5px;
}

.timeBox {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.timeText {
  min-width: 50px;
  color: var(--black);
  font-size: 14px;
  font-weight: 400;
}

.timeText2 {
  min-width: 50px;
  color: var(--black);
  font-size: 14px;
  font-weight: 400;
  margin-left: 20px;
}

.timeBox > p {
  color: var(--black);
  font-size: 14px;
  font-weight: 400;
  margin-left: 5px;
  margin-right: 20px;
}

.timeBox > p:last-child {
  margin-right: 0px !important;
}

.customInputBoxDate {
  display: flex;
  align-items: center;
  position: relative;
  font-weight: 500;
  font-size: 16px;
  padding: 0px 22px;
  width: 100%;
  min-height: 55px;
  background: #ffffff;
  border: 1px solid var(--light-grey);
  border-radius: 10px;
  margin-top: 5px;
}

.imageContainer {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 18px;
  margin-bottom: 11px;
}

.afterImageContainer {
  cursor: pointer;
  border-radius: 13px;
  width: 79px;
  height: 79px;
  object-fit: cover;
}

.deleteButton {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 3px 0;
  border-radius: 5px;
  font-size: 14px;
  color: #ffffff;
  background: var(--red);
}

.profileBox {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 79px;
  height: 79px;
  border-radius: 13px;
  background: var(--background-grey);
  object-fit: cover;
}

.genderRadioGroup {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin-top: 17px;
  margin-bottom: 10px;
}

.radioBox {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 41px;
}

.selectedRadio {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 19px;
  height: 19px;
  border-radius: 50%;
  border: 1px solid var(--primary);
}

.selectedRadioInner {
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background: var(--primary);
}

.unselectedRadio {
  width: 19px;
  height: 19px;
  border-radius: 50%;
  border: 1px solid var(--light-grey);
}

.radioText {
  color: var(--black);
  font-size: 14px;
  font-weight: 600;
  margin-left: 7px;
}

.postPopupContainer {
  z-index: 3;
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  text-align: center;
  width: 100%;
  height: 100%;
  /*  Any height is allowed, also in %. */
  background-color: rgba(0, 0, 0, 0.5);
}

.postInnerPopup {
  align-items: center;
  border-radius: 4px;
  position: absolute;
  display: flex;
  flex-direction: column;
  padding-left: 10px;
  padding-right: 10px;
  max-width: 100%;
  top: 50%;
  left: 50%;
  width: 520px;
  height: auto;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background: #ececec;
}

.daumPostPopup {
  padding-top: 10px;
  width: 500px;
  height: 400px;
}

.postPopupCloseButton {
  display: flex;
  cursor: pointer;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 4px;
  text-align: center;
  font-size: 18px;
  font-weight: 700;
  width: 200px;
  height: 40px;
  color: #5e5e5e;
  background-color: white;
  align-items: center;
  justify-content: center;
}

.dayText {
  color: var(--black);
  font-size: 16px;
  font-weight: 500;
  margin-right: 7px;
}

.inputTitleDay {
  width: 100%;
  color: var(--black);
  font-size: 14px;
  font-weight: 400;
  margin-top: 20px;
}

.rowBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 5px;
}

.dateRadioBox {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 70px;
  margin: 0 20px;
}

.allTimeRadioBox {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 10px 0px;
}

.defaultBox {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: relative;
  font-weight: 500;
  font-size: 16px;
  padding: 0px 22px;
  width: 100px;
  min-height: 40px;
  background: var(--background-grey);
  color: var(--light-grey);
  border: 1px solid var(--light-grey);
  border-radius: 10px;
  margin: 0 5px;
}

.customInputBoxDate {
  display: flex;
  align-items: center;
  text-align: center;
  position: relative;
  font-weight: 500;
  font-size: 14px;
  padding: 0px 22px;
  width: 100px;
  min-height: 40px;
  background: #ffffff;
  border: 1px solid var(--light-grey);
  border-radius: 10px;
  margin: 0 5px;
}

.quillBox {
  padding: 5px;
  font-weight: 500;
  font-size: 16px;
  width: 100%;
  white-space: nowrap;
  min-height: 55px;
  overflow-y: hidden;
  background: #ffffff;
  border: 1px solid var(--light-grey);
  border-radius: 10px;
  margin-top: 5px;
  resize: none;
}

.quillBox:focus {
  outline: none;
  border: 1px solid #000000;
  background: #ffffff;
}

.checkBoxLabel {
  margin-top: 5px;
}

/* Hide the browser's default checkbox */
.checkBoxLabel input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
  
/* Create a custom checkbox */
.checkMark {
  position: relative;
  top: 0;
  left: 0;
  min-width: 20px;
  min-height: 20px;
  background: #FFFFFF;
  border: 1px solid #DBDBDB;
  border-radius: 5px;
  margin-right: 10px;
}
  
/* When the checkbox is checked, add a blue background */
.checkBoxLabel input:checked ~ .checkMark {
  background-color: var(--primary);
}
  
/* Create the checkMark/indicator (hidden when not checked) */
.checkMark:after {
  content: "";
  position: absolute;
  display: none;
}
  
/* Show the checkMark when checked */
.checkBoxLabel input:checked ~ .checkMark:after {
  display: block;
}
  
/* Style the checkMark/indicator */
.checkBoxLabel .checkMark:after {
  left: 7px;
  top: 4px;
  width: 3px;
  height: 5.5px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.checkBoxLabel {
  font-size: 14px;
  align-items: center;
  cursor: pointer;
  display: inline-flex;
}