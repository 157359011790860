.App {
  text-align: center;
}

p {
  margin: 0;
}

body {
  overflow-x: hidden;
  color: var(--black);
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

:root {
  --pure-white: #ffffff;
  --pure-black: #000000;
  --black: #4d5661;
  --background-grey: #f7f8fa;
  --light-grey: #dbdbdb;
  --light-grey2: #d9d9d9;
  --text-grey: #adadad;
  --text-grey2: #c4c4c4;
  --text-grey3: #a5a1a1;
  --dark-grey: #595757;
  --input-box-grey: #f8f8f8;
  --white-grey: #f4f4f4;
  --navigator: #212528;
  --nav-selected: #404040;
  --scroll-item-grey: #4d5661;
  --red: #f95050;
  --blue: #4788f4;
  --orange: #f5980d;
  --apricot: rgba(245, 152, 13, 0.1);
  --excel-green: #207344;
  --logo: #05c3a7;
  --opacityLogo: rgba(5, 233, 199, 0.05);
  --primary: #05e9c7;
  --op-primary: #f2fefc;
  --primary-5: rgba(5, 233, 199, 0.05);
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.row-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
}

.row-box .react-datepicker-wrapper {
  width: 110px;
}

/* date-picker */
.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  display: none;
}

.react-datepicker__header__dropdown {
  display: flex;
  justify-content: center;
  flex-direction: row-reverse;
  margin-bottom: 15px;
}

.react-datepicker__month-dropdown-container,
.react-datepicker__year-dropdown-container {
  margin: 0px 5px !important;
}

.react-datepicker__year-select {
  border-radius: 5px;
  font-size: 14px;
  width: 60px;
  height: 32px;
}

.react-datepicker__month-select {
  border-radius: 5px;
  font-size: 14px;
  width: 50px;
  height: 32px;
}

/* 웹킷 기반 브라우저 */
::-webkit-scrollbar {
  width: 12px; /* 스크롤바 너비 */
  height: 12px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1; /* 스크롤바 트랙 배경색 */
}

::-webkit-scrollbar-thumb {
  background-color: #888; /* 스크롤바 색상 */
  border-radius: 5px; /* 스크롤바 모서리 반경 */
}

/* quill-editor */
.ql-editor {
  min-height: 500px;
}
