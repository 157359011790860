.wrapper {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 0 30px;
    background: #ffffff;
    overflow: auto;
    z-index: 9999;
}

.warningIcon {
    margin-bottom: 64px;
    min-height: 65px;
}

.firstText {
    color: var(--black);
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 15px;
}

.secondText {
    color: var(--black);
    font-size: 14px;
    font-weight: 500;
}

.errorButton {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 55px;
    border-radius: 10px;
    background: var(--primary);
    color: #ffffff;
    font-size: 20px;
    font-weight: 600;
    margin-top: 74px;
}

.emptySpace {
    width: 100%;
    min-height: 100px;
}