.map-horiaontal .react-horizontal-scrolling-menu--scroll-container {
  display: flex;
  align-items: center;
  overflow-y: hidden;
  position: relative;
  width: 100%;
  height: 45px;
}

.react-horizontal-scrolling-menu--scroll-container {
  display: flex;
  height: max-content;
  overflow-y: hidden;
  position: relative;
  width: 100%;
}
.react-horizontal-scrolling-menu--scroll-container {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar {
  display: none;
}
.react-horizontal-scrolling-menu--scroll-container.rtl {
  direction: rtl;
}
.react-horizontal-scrolling-menu--inner-wrapper {
  display: flex;
  overflow-y: hidden;
}
.react-horizontal-scrolling-menu--wrapper {
  display: flex;
  flex-direction: column;
}
.react-horizontal-scrolling-menu--footer,
.react-horizontal-scrolling-menu--header {
  width: 100%;
}
.react-horizontal-scrolling-menu--arrow-left,
.react-horizontal-scrolling-menu--arrow-right {
  display: flex;
}
