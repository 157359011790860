.wrapper {
  position: absolute;
  top: 0px;
  left: 263px;
  width: calc(100vw - 263px);
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  background-color: var(--background-grey);
}

.innerWrapper {
  padding: 50px;
}

.titleText {
  font-size: 25px;
  font-weight: 700;
  margin-bottom: 40px;
}

.filterSection {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 20px 5px;
  margin-bottom: 20px;
  background-color: #eee;
}

.selectBox,
.searchBox {
  font-size: 16px;
  padding: 0px 10px;
  border: 1px solid var(--light-grey);
  height: 40px;
  border-radius: 5px;
}

.filterBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.searchButton {
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  min-width: 70px;
  height: 40px;
  background: #ffffff;
  border: 1px solid var(--light-grey);
  border-radius: 5px;
  font-weight: 500;
  font-size: 16px;
}

.excelButton {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 125px;
  min-height: 38px;
  background: var(--excel-green);
  border: 1px solid var(--light-grey);
  border-radius: 5px;
  color: #ffffff;
  font-weight: 500;
  font-size: 16px;
}

.listContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.registerButton {
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  min-width: 70px;
  min-height: 40px;
  background: #ffffff;
  border: 1px solid var(--light-grey);
  border-radius: 5px;
  font-weight: 500;
  font-size: 16px;
}

.modifyButton {
  display: inline-flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  width: 70px;
  min-height: 40px;
  background: #ffffff;
  border: 1px solid var(--light-grey);
  border-radius: 5px;
  font-weight: 500;
  font-size: 16px;
}

.tableContainer {
  width: 100%;
  overflow-x: auto; /* 가로 스크롤 활성화 */
}

.customTable {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
  font-size: 18px;
  text-align: left;
}

.customTable thead tr {
  background-color: var(--primary);
  color: white;
  text-align: left;
  font-size: 16px;
  font-weight: bold;
}

.customTable th,
.customTable td {
  height: 50px;
  padding: 8px 15px;
  text-align: center; /* 가운데 정렬 */
  font-size: 16px;
  word-wrap: break-word; /* 단어가 너무 길면 줄바꿈 */
  white-space: normal; /* 공백에서 줄바꿈 */
}

.customTable tr {
  background-color: var(--pure-white);
}

.customTable tbody tr {
  border-bottom: 1px solid #dddddd;
  cursor: pointer;
}

.customTable tbody tr:last-of-type {
  border-bottom: 2px solid var(--primary);
}

.customTable tbody tr:hover {
  background-color: var(--op-primary);
}

.pagingContainer {
  display: flex;
  justify-content: center;
  align-items: end;
  flex: 1;
}

/* 미디어 쿼리 */
@media (max-width: 1200px) {
  .filterSection {
    flex-direction: column;
    align-items: center;
  }

  .selectBox,
  .searchBox,
  .searchButton {
    width: 100%;
    margin-bottom: 10px;
  }
}
