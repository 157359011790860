.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  animation-name: fadeIn;
  animation-duration: 0.2s;
  animation-delay: 0.01s;
  animation-fill-mode: forwards;
  z-index: 9999999;
}

.innerWrapper {
  display: flex;
  position: relative;
  flex-direction: column;
  background: #ffffff;
  width: 80%;
  max-height: 80%;
  border-radius: 13px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  overflow: auto;
}

.dialogContainer {
  display: flex;
  flex-direction: column;
  align-items: start;
  padding: 30px;
  min-width: 100%;
  width: 100%;
  height: 100%;
  overflow: auto;
}

.dialogContainer::-webkit-scrollbar {
  width: 10px;
}

.dialogContainer::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: var(--text-grey2);
  width: 10px;
}

.dialogContainer::-webkit-scrollbar-track {
  border-top-right-radius: 13px;
  border-bottom-right-radius: 13px;
  background-color: #ffffff;
}

.dialogCloseButton {
  position: absolute;
  cursor: pointer;
  top: 10px;
  right: 20px;
}

.title {
  width: 100%;
  color: var(--black);
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 21px;
}

.loginButton {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 50px;
  border-radius: 10px;
  background: var(--primary);
  color: #ffffff;
  font-size: 18px;
  font-weight: 600;
  margin-top: 20px;
}

.closeButton {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 50px;
  border-radius: 10px;
  border: 1px solid var(--primary);
  color: var(--primary);
  font-size: 18px;
  font-weight: 600;
  margin-top: 20px;
}

.fade-in {
  opacity: 1;
  animation: fadeIn ease-in-out 0.2s;
}

.fade-out {
  opacity: 0;
  animation: fadeOut ease-in-out 0.2s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.imageBox {
  width: 80px;
  height: 80px;
  object-fit: cover;
}

.inputTitle {
  width: 100%;
  color: var(--black);
  font-size: 14px;
  font-weight: 400;
  margin-top: 20px;
}

.inputTopTitle {
  width: 100%;
  color: var(--black);
  font-size: 14px;
  font-weight: 400;
}

.inputId {
  font-weight: 500;
  font-size: 16px;
  padding: 0px 22px;
  width: 100%;
  min-height: 55px;
  background: #ffffff;
  border: 1px solid var(--light-grey);
  border-radius: 10px;
  margin-top: 5px;
}

.inputId:focus {
  outline: none;
  border: 1px solid #000000;
  background: #ffffff;
}

.imageContainer {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 79px;
  margin-top: 18px;
  margin-bottom: 11px;
}

.afterImageContainer {
  border-radius: 13px;
  width: 79px;
  height: 79px;
  object-fit: cover;
}

.profileBox {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 79px;
  height: 79px;
  border-radius: 13px;
  background: var(--background-grey);
  object-fit: cover;
}

.genderRadioGroup {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin-top: 17px;
  margin-bottom: 10px;
}

.radioBox {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 41px;
}

.selectedRadio {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 19px;
  height: 19px;
  border-radius: 50%;
  border: 1px solid var(--primary);
}

.selectedRadioInner {
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background: var(--primary);
}

.unselectedRadio {
  width: 19px;
  height: 19px;
  border-radius: 50%;
  border: 1px solid var(--light-grey);
}

.radioText {
  color: var(--black);
  font-size: 14px;
  font-weight: 600;
  margin-left: 7px;
}

.quillBox {
  padding: 5px;
  font-weight: 500;
  font-size: 16px;
  width: 100%;
  white-space: nowrap;
  min-height: 55px;
  overflow-y: hidden;
  background: #ffffff;
  border: 1px solid var(--light-grey);
  border-radius: 10px;
  margin-top: 5px;
  resize: none;
}

.quillBox:focus {
  outline: none;
  border: 1px solid #000000;
  background: #ffffff;
}
