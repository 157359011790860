.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  animation-name: fadeIn;
  animation-duration: 0.2s;
  animation-delay: 0.01s;
  animation-fill-mode: forwards;
  z-index: 9999999;
}

.innerWrapper {
  display: flex;
  position: relative;
  flex-direction: column;
  background: #ffffff;
  width: 80%;
  max-height: 80%;
  border-radius: 13px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  overflow: auto;
}

.dialogContainer {
  display: flex;
  flex-direction: column;
  padding: 50px 30px 30px 30px;
  min-width: 100%;
  width: 100%;
  height: 100%;
  overflow: auto;
}

.dialogContainer::-webkit-scrollbar {
  width: 10px;
}

.dialogContainer::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: var(--text-grey2);
  width: 10px;
}

.dialogContainer::-webkit-scrollbar-track {
  border-top-right-radius: 13px;
  border-bottom-right-radius: 13px;
  background-color: #ffffff;
}

.title {
  font-size: 25px;
  font-weight: 700;
  margin-bottom: 10px;
}

.rowBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.agreeLabel {
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-bottom: 10px;
}

.agreeLabel input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkMark {
  position: relative;
  top: 0;
  left: 0;
  min-width: 26px;
  min-height: 26px;
  background: #ffffff;
  border: 1px solid var(--light-grey);
  border-radius: 5px;
}

.agreeLabel input:checked ~ .checkMark {
  background-color: var(--primary);
}

.checkMark:after,
.checkMark2:after {
  content: '';
  position: absolute;
  display: none;
}

.agreeLabel input:checked ~ .checkMark:after {
  display: block;
}

.agreeLabel .checkMark:after {
  left: 8px;
  top: 4px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.agreeLabel {
  font-size: 14px;
  align-items: center;
  cursor: pointer;
  display: inline-flex;
}

.agreeAllText {
  min-width: fit-content;
  color: var(--black);
  font-size: 14px;
  font-weight: 600;
  margin-left: 7px;
}

.genderRadioGroup {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 17px;
  margin-bottom: 10px;
}

.radioBox {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 41px;
}

.selectedRadio {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 19px;
  height: 19px;
  border-radius: 50%;
  border: 1px solid var(--primary);
}

.selectedRadioInner {
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background: var(--primary);
}

.unselectedRadio {
  width: 19px;
  height: 19px;
  border-radius: 50%;
  border: 1px solid var(--light-grey);
}

.radioText {
  color: var(--black);
  font-size: 14px;
  font-weight: 600;
  margin-left: 7px;
}

.dialogCloseButton {
  position: absolute;
  cursor: pointer;
  top: 10px;
  right: 20px;
}

.quillEditor {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: var(--pure-white);
}

.inputId {
  font-weight: 500;
  font-size: 16px;
  padding: 0px 22px;
  width: 100%;
  min-height: 50px;
  background: var(--pure-white);
  border: 1px solid var(--light-grey);
  border-radius: 10px;
  margin-bottom: 14px;
}

.button {
  cursor: pointer;
  display: flex;
  align-self: flex-end;
  justify-content: center;
  align-items: center;
  width: 100px;
  min-height: 40px;
  border-radius: 8px;
  background: var(--blue);
  color: #ffffff;
  margin-top: 20px;
}

.emptySpace {
  width: 100%;
  min-height: 50px;
}

.fade-in {
  opacity: 1;
  animation: fadeIn ease-in-out 0.2s;
}

.fade-out {
  opacity: 0;
  animation: fadeOut ease-in-out 0.2s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
