/* Resetting button styles */
button {
  background: none; /* 배경색 제거 */
  border: none; /* 테두리 제거 */
  padding: 0; /* 패딩 제거 */
  margin: 0; /* 마진 제거 */
  font: inherit; /* 글꼴 스타일 상속 */
  color: inherit; /* 글자 색상 상속 */
  cursor: pointer; /* 포인터 커서 */
  outline: none; /* 포커스 시 윤곽선 제거 */
}

/* a 태그 기본 스타일 제거 */
a {
  text-decoration: none; /* 밑줄 제거 */
  color: inherit; /* 부모 색상 사용 */
}

/* Resetting default styles for ul and li */
.menuList,
.subMenuList {
  list-style-type: none; /* 기본 점 표시 제거 */
  margin: 0; /* 기본 마진 제거 */
  padding: 0; /* 기본 패딩 제거 */
}

.wrapper {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  width: 263px;
  height: 100%;
  background: var(--navigator);
}

.logoBox {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100px;
}

.logoBox > div {
  cursor: pointer;
}

.logoBox > div > svg:first-child {
  width: 49px;
  height: 40px;
}

.logoBox > div > svg:last-child {
  width: 102px;
  height: 30px;
  margin-left: 15px;
}

.scrollMenuItems {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  overflow: auto;
  padding-bottom: 50px;
}

.scrollMenuItems::-webkit-scrollbar {
  width: 10px;
}

.scrollMenuItems::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: var(--text-grey2);
  width: 10px;
}

.scrollMenuItems::-webkit-scrollbar-track {
  background-color: var(--black);
}

.mainMenu {
  display: flex;
  flex-direction: row;
  font-size: 13px;
  font-weight: 600;
  margin-top: 30px;
  padding: 0px 45px;
  color: var(--text-grey2);
}

.subMenu {
  cursor: pointer;
  color: #ffffff;
  font-size: 16px;
  font-weight: 600;
  margin: 10px 0;
  padding: 10px 0 10px 45px;
}

.selectedMenu {
  cursor: pointer;
  background: var(--nav-selected);
  color: #ffffff;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 600;
  margin: 10px 20px;
  padding: 10px 0 10px 25px;
}

.logoutBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.profileBox {
  width: calc(100% - 50px);
  margin-bottom: 20px;
}

.adminTitle {
  font-size: 12px;
  font-weight: 600;
  color: var(--light-grey2);
  margin-bottom: 5px;
}

.rowBox {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.rowBox p {
  font-size: 16px;
  font-weight: 600;
  color: var(--pure-white);
}

.logoutBox {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.logoutButton {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  width: 95%;
  font-size: 16px;
  font-weight: 700;
  color: var(--black);
  height: 44px;
  background-color: #ffffff;
  border-radius: 8px;
}
