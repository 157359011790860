.wrapper {
  position: absolute;
  top: 0px;
  left: 263px;
  width: calc(100vw - 263px);
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  background-color: var(--background-grey);
}

.innerWrapper {
  padding: 50px;
}

.titleText {
  font-size: 25px;
  font-weight: 700;
  margin-bottom: 40px;
}

.aboveContainer {
  display: flex;
  min-width: 1080px;
  width: 100%;
}

.totalCount {
  margin: 10px 0px;
}

.searchInput {
  font-weight: 500;
  font-size: 16px;
  padding: 0px 10px;
  width: 250px;
  min-height: 40px;
  background: #ffffff;
  border: 1px solid var(--light-grey);
  border-radius: 10px;
}

.searchInput:focus {
  outline: none;
  border: 2px solid #2684ff;
  background: #ffffff;
}

.searchButton {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 70px;
  padding: 0 10px;
  min-height: 40px;
  background: #ffffff;
  border: 1px solid var(--light-grey);
  border-radius: 10px;
  font-weight: 500;
  font-size: 16px;
  margin-left: 10px;
}

.searchButtonSelect {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 70px;
  padding: 0 10px;
  min-height: 40px;
  background: #ffffff;
  border: 1px solid var(--blue);
  border-radius: 10px;
  font-weight: 500;
  font-size: 16px;
  color: var(--blue);
  margin-left: 10px;
}

.addButton {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 40px;
  border-radius: 8px;
  background: var(--blue);
  color: #ffffff;
}

.selectBox,
.searchBox {
  font-size: 16px;
  padding: 0px 10px;
  border: 1px solid var(--light-grey);
  height: 40px;
  border-radius: 5px;
}

.searchButton {
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  min-width: 70px;
  height: 40px;
  background: #ffffff;
  border: 1px solid var(--light-grey);
  border-radius: 5px;
  font-weight: 500;
  font-size: 16px;
}

.listContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.linkStyle {
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}

.button {
  display: inline-block;
  padding: 10px 20px;
  margin: 5px;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  color: #ffffff;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.button:hover {
  background-color: #0056b3;
}

.rbutton {
  display: inline-block;
  padding: 10px 20px;
  margin: 5px;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  color: #ffffff;
  background-color: #dc3545;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.rbutton:hover {
  background-color: #c82333;
}

.registerButton {
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  min-width: 70px;
  min-height: 40px;
  background: #ffffff;
  border: 1px solid var(--light-grey);
  border-radius: 5px;
  font-weight: 500;
  font-size: 16px;
}

.modifyButton {
  display: inline-flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  width: 70px;
  min-height: 40px;
  background: #ffffff;
  border: 1px solid var(--light-grey);
  border-radius: 5px;
  font-weight: 500;
  font-size: 16px;
}

.tableContainer {
  width: 100%;
  overflow-x: auto; /* 가로 스크롤 활성화 */
}

.customTable {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
  font-size: 18px;
  text-align: left;
}

.customTable thead tr {
  background-color: var(--primary);
  color: white;
  text-align: left;
  font-size: 16px;
  font-weight: bold;
}

.customTable th,
.customTable td {
  height: 50px;
  padding: 8px 15px;
  text-align: center; /* 가운데 정렬 */
  font-size: 16px;
  word-wrap: break-word; /* 단어가 너무 길면 줄바꿈 */
  white-space: normal; /* 공백에서 줄바꿈 */
}

.customTable tr {
  background-color: var(--pure-white);
}

.customTable tbody tr {
  border-bottom: 1px solid #dddddd;
}

.customTable tbody tr:last-of-type {
  border-bottom: 2px solid var(--primary);
}

.customTable tbody tr:hover {
  background-color: var(--op-primary);
}

.pagingContainer {
  display: flex;
  justify-content: center;
  align-items: end;
  flex: 1;
}

.scrollMenu {
  padding: 0 15px;
  width: 100%;
}

.horizontalScrollMenu {
  z-index: 2;
  width: 100%;
  overflow-x: scroll;
  white-space: nowrap;
}

.menuItem {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  font-size: 14px;
  font-weight: bold;
  color: #4d5661;
  width: auto;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 40px;
  background-color: #ffffff;
  border-radius: 10px;
  border: transparent;
  cursor: pointer;
  margin-right: 10px;
}

.slectedMenuItem {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  font-size: 14px;
  font-weight: bold;
  color: white;
  width: auto;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 40px;
  background-color: var(--primary);
  border-radius: 10px;
  border: transparent;
  cursor: pointer;
  margin-right: 10px;
}

.rowBox {
  display: flex;
  width: 100%;
  justify-content: space-around;
  flex-direction: row;
  align-items: center;
  padding-right: 10px;
  margin-top: 5px;
}

.modifyMenu {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40%;
  height: 20px;
  border-radius: 5px;
  color: #ffffff;
  background: var(--blue);
}

.pencilIcon {
  width: 15px;
}

.deleteMenu {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40%;
  height: 20px;
  border-radius: 5px;
  color: #ffffff;
  background: var(--red);
}

.deleteIcon {
  width: 15px;
}
